import React from 'react';

import '../styles/Testimonials.scss';

const Testimonials = (props) => {
    return (
        <section id="testimonials" className="space-before">
            <div className="container">
                <h1 className="title">
                    {props.data.testimonials.testimonialsTitle}
                </h1>
                <h2 className="text">
                    {props.data.testimonials.testimonialsText}
                </h2>
                <h3 className="author">
                    {props.data.testimonials.testimonialsAuthor} - <a href='https://www.aperturedata.io/' target='blank'>{props.data.testimonials.testimonialsAuthorCompany}</a>
                </h3>
                <h2 className="text">
                    {props.data.testimonials.testimonialsText2}
                </h2>
                <h3 className="author">
                    {props.data.testimonials.testimonialsAuthor2} - <a href='https://theuxdepartment.com/' target='blank'>{props.data.testimonials.testimonialsAuthorCompany2}</a>
                </h3>
            </div>
        </section>
    );
}

export default React.memo(Testimonials);
